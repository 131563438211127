import clsx from 'clsx';

export const PrintOnly = ({ children, className = '', ...rest }) => (
  <span {...rest} className={clsx('print', className)}>
    {children}
  </span>
);
export const ScreenOnly = ({ children, className = '', ...rest }) => (
  <span {...rest} className={clsx('screen', className)}>
    {children}
  </span>
);
export const Link = ({ url, children, ...rest }) => (
  <a {...rest} href={url}>
    {children}
  </a>
);

export const PrintableLink = ({
  text,
  link,
  leftPrint,
  rightPrint,
  ...rest
}) => (
  <span {...rest}>
    <PrintOnly>
      {text} {leftPrint}
      <Link url={link}>{link}</Link>
      {rightPrint}
    </PrintOnly>
    <ScreenOnly>
      <Link url={link}>{text}</Link>
    </ScreenOnly>
  </span>
);
