import { Component } from 'react';
import './App.css';
import { Link, PrintableLink, PrintOnly, ScreenOnly } from './components';
import Logo from './Logo';

const smashdexUrl = 'https://melee.smashdex.app/';
const smashdexProjectTitle = 'smashdex';

const googleCloudBuildArticleTitle =
  'How to set up GitHub integration and automated deploys to Firebase with Google Cloud Build';
const googleCloudBuildArticleUrl =
  'https://hackernoon.com/how-to-set-up-github-integration-and-automated-deploys-to-firebase-with-google-cloud-build-54696dc12991';
const shortenedGoogleCloudBuildArticleUrl = 'https://bit.ly/2IsvXic';

const SmashdexTitleText = () => (
  <span className='smashdexTitleText'>{smashdexProjectTitle}</span>
);

const phoneNumberMaybe = false ? ' (330) 309-6048 |' : '';

class App extends Component {
  render() {
    return (
      <div className='App'>
        <div className='Resume'>
          <header className='blue-bg'>Chase Holdren</header>
          <div className='ContactInfo dark-blue-bg'>
            Akron, OH |{phoneNumberMaybe} minntyy@gmail.com |{' '}
            <a href='https://www.linkedin.com/in/choldren/'>
              linkedin.com/in/choldren
            </a>{' '}
            | <a href='https://chaseholdren.com'>chaseholdren.com</a> |{' '}
            <a href='https://github.com/chaseholdren'>
              github.com/chaseholdren
            </a>
          </div>
          <div className='Intro'>
            <h3 style={{ fontSize: '19px' }}>
              Senior Full Stack Software Developer | Staff Front-End Product
              Engineer
            </h3>
            Full-Stack Developer and API Architect with over 8 years of
            experience in front-end and back-end development to steer diverse
            projects, leveraging Microsoft Teams plugins, internal pricing
            tools, and custom code editors. Mastered Node, Vue, and Microsoft
            Graph API for web app features and bug fixes. Harmonized with
            cross-functional teams to design and implement features while
            providing technical leadership to guide architectural decisions.
            Crafted clear, maintainable code, conduct code and design reviews,
            and uphold high-quality standards. Led transition to integrated
            plugins with innovative caching solutions. Exhibited proficiency in
            React, TypeScript, and Material-UI for architecting cutting-edge
            tools.
            <h3>Areas of Expertise</h3>
            Full-Stack Software Design & Development | Product/Project
            Management | Front-End/Back-End Technologies Innovation Web-Based
            Applications | Testing & Debugging | Team Leadership | UI/UX
            Frameworks | Coding Best Practices | Data Transmission Google Cloud
            Platform (GCP) | Software as a Service (SaaS) | Database
            Structures/Algorithms | Object-Oriented Programming (OOP)
            Cross-Functional Teamwork | Continuous Integration/Continuous
            Deployment (CI/CD) | Code Quality & Documentation
          </div>
          <div style={{ width: '100%' }}>
            <h2 className='blue-bg'>Professional Experience</h2>
            <h3 className='header-date'>
              <span>Full Stack Developer | Frameable Inc.</span>
              <span>Mar 2022 - Jan 2024</span>
            </h3>
            <ul>
              <li>
                Engineered web app features and resolved bugs by deploying Node,
                Vue, and the Microsoft Graph API.
              </li>
              <li>
                Enhanced app functionality through integration of the Microsoft
                Graph API.
              </li>
              <li>
                Piloted transition from standalone web apps to integrated MS
                Teams plugins and overcame technical challenges with innovative
                caching solutions.
              </li>
              <li>
                Transformed product into MS Teams plugins by developing a
                Virtual Office Web App with video calls and screen-sharing
                functionalities.
              </li>
              <li>
                Navigated front-end (Vue) and back-end (Node, Express, Passport)
                development in designing responsive apps.
              </li>
              <li>
                Led efforts to maintain optimal plugin loading speed within MS
                Teams and eliminated performance challenges.
              </li>
            </ul>
            <h3 className='header-date'>
              <span>Application Developer | Insight2Profit</span>
              <span>Aug 2019 – Mar 2022</span>
            </h3>
            <ul>
              <li>
                Empowered Analysts by constructing an internal tool with React
                and allowed them to create pricing/quoting models directly
                accessible as APIs:
              </li>
              <ul>
                <li>
                  Eliminated developer multitasking and accelerated project
                  delivery.
                </li>
              </ul>
              <li>
                Ascertained optimal interaction even with multiple code editors
                through Identification and resolution of performance
                bottlenecks, opting for a custom solution over the Monaco
                editor.
              </li>
              <li>
                Pioneered the foundation of a new project by designing and
                building an internal tool:
              </li>
              <ul>
                <li>
                  Encouraged Pricing Consultants to construct financial models
                  as APIs without requiring coding knowledge.
                </li>
              </ul>
              <li>
                Architected visually appealing tools by utilizing TypeScript,
                React, and Material-UI.
              </li>
              <li>
                Optimized usability and maintainability by formulating a
                streamlined custom code editor in the Language Server Protocol.
              </li>
            </ul>
            <h3 className='header-date'>
              <span>
                Software Engineer – Developer II | Hyland Software (Lawlogix)
              </span>
              <span>July 2016 – Aug 2019</span>
            </h3>
            <ul>
              <li>
                Contributed to sustainable solution by directing a complete
                rewrite of an aged 4D/Python API to C# while utilizing .NET,
                Entity Framework, and PostgreSQL.
              </li>
              <li>
                Solidified code quality and certified future growth by reducing
                bug influx associated with new feature additions.
              </li>
              <li>
                Facilitated knowledge transfer and developer onboarding by
                transitioning from proprietary 4D to widely adopted C#, which
                opened the codebase to a broader talent pool.
              </li>
              <li>
                Expanded technical skillset and versatility through hands-on
                development practices in .NET, Entity Framework, PostgreSQL,
                Jenkins, and Docker.
              </li>
            </ul>
          </div>
          <div className='key-projects pagebreak'>
            <h2 className='blue-bg'>Key Projects</h2>
            <h3 className='ProjectName'>
              <Link url='Frostbase.app'>Frostbase.app</Link>
            </h3>
            Conceptualized and developed a unique virtual chat room experience,
            immersing users in an interactive glacier environment as
            customizable bears, that:
            <ul>
              <li>
                Users personalize their experience by choosing their bear avatar
                and navigating the 3D world with intuitive click-to-move and
                camera controls.
              </li>
              <li>
                Facilitated real-time communication through text chat that
                fostered connection and engagement within the virtual space.
              </li>
              <li>
                Utilized a powerful tech stack including Three.js for 3D
                graphics, react-three-fiber for React integration, Express for
                server-side logic, and web sockets with socket.io.
              </li>
              <li>
                Prioritized user experience by allowing fake email addresses for
                registration and recommending desktop/laptop usage for optimal
                performance.
              </li>
              <li>
                Leveraged Google Cloud Platform App Engine to ensure reliable
                and adaptable hosting for the virtual world.
              </li>
            </ul>
            <PrintOnly>
              <h3 className='ProjectName smashdexHeadline'>
                <SmashdexTitleText />
                <Logo />
                <span style={{ whiteSpace: 'pre' }}>- </span>
                <Link url={smashdexUrl}>{smashdexUrl}</Link>
              </h3>
            </PrintOnly>
            <ScreenOnly>
              <h3 className='ProjectName smashdexHeadline'>
                <Link url={smashdexUrl}>
                  <SmashdexTitleText />
                </Link>
                <Logo />
              </h3>
            </ScreenOnly>
            Crafted and launched “Smashdex”, an innovative progressive web app
            (PWA) which allowed Super Smash Bros melee players to track and
            analyze their gameplay statistics, that:
            <ul>
              <li>
                Users effortlessly record their match history through manual
                entry or convenient import options.
              </li>
              <li>
                Comprehensive charts and data visualizations unlock valuable
                insights into individual performance and matchup breakdowns.
              </li>
              <li>
                Delivers a native app-like experience accessible across devices
                without app store restrictions.
              </li>
            </ul>
            <h3 className='ProjectName'>Web LEDs</h3>
            <span>
              Engineered{' '}
              <q>
                <PrintableLink
                  text='Web LEDs'
                  link='https://github.com/chaseholdren/web-LEDs'
                  leftPrint='('
                  rightPrint=')'
                />
              </q>
              , a captivating interactive art installation where online drawings
              instantly illuminate an 8x32 LED matrix:
            </span>
            <ul>
              <li>
                Wired the LED matrix to an Arduino and crafted the physical
                foundation for visual expression.
              </li>
              <li>
                Developed a Node server to efficiently manage LED data
                transmission over the serial port.
              </li>
              <li>
                Launched a{' '}
                <PrintableLink
                  text='website'
                  link='https://web-leds.chaseholdren.com'
                  leftPrint='('
                  rightPrint=')'
                />{' '}
                where users contribute to the art by drawing directly on the
                grid.
              </li>
            </ul>
            <h3 className='ProjectName'>Article published on Hackernoon.com</h3>
            <span>
              Published an article on HackerNoon, "
              <PrintOnly>
                {googleCloudBuildArticleTitle} <br />-{' '}
                <Link url={shortenedGoogleCloudBuildArticleUrl}>
                  {shortenedGoogleCloudBuildArticleUrl}
                </Link>
              </PrintOnly>
              <ScreenOnly>
                <Link url={googleCloudBuildArticleUrl}>
                  {googleCloudBuildArticleTitle}
                </Link>
              </ScreenOnly>
              ":
            </span>
            <ul>
              <li>
                Achieved 13,704 views which indicated significant interest in
                content within the developer community.
              </li>
              <li>
                Estimated 33% read-through rate and exceeding Medium's average
                audience attention.
              </li>
            </ul>
          </div>
          <div className='education'>
            <h2 className='blue-bg'>Education</h2>
            <span>
              <b>Bachelor of Computer Information Systems </b> – Kent State
              University | Kent, OH | 2016
            </span>
          </div>
          <h2 className='blue-bg'>Technical Skills</h2>
          React | Vue | Node | Express | Docker | Typescript | HTML | CSS |
          Jenkins | ASP.Net | PostgreSQL | Redux | C# | Material UI | Azure |
          Microsoft Graph API | Firebase | JavaScript | GraphQL
          <h2 className='blue-bg'>Certifications & Courses</h2>
          <span class='certs-courses'>
            <b>Sololearn | Certificate of Completion: Python 3 Course</b> | 2016
          </span>
          <span>
            <b>Courses: </b>Computer Applications, Database Management Systems,
            Web Programming, Programming Mobile Apps, &{' '}
            <a href='https://www.linkedin.com/in/choldren/details/courses/'>
              More Courses
            </a>
            .
          </span>
          <div className='volunteer'>
            <h2 className='blue-bg'>Volunteer Experience</h2>
            <ul>
              <li>
                Designed a website for managing contact with sponsored girls in
                collaboration with the CEO of Insight2Profit and Drop in the
                Bucket (a charity providing access to education and clean water
                in Africa).
              </li>
              <li>
                Streamlined communication and fostered engagement between
                sponsors and girls, promoting continued basic support and
                educational opportunities.
              </li>
            </ul>
          </div>
          {/* <div className='Play'>
            <h2>Projects</h2>
            <Frostbase />
            <PrintOnly>
              <div className='ProjectName smashdexHeadline'>
                <SmashdexTitleText />
                <Logo />
                <span style={{ whiteSpace: 'pre' }}>- </span>
                <Link url={smashdexUrl}>{smashdexUrl}</Link>
              </div>
            </PrintOnly>
            <ScreenOnly>
              <div className='ProjectName smashdexHeadline'>
                <Link url={smashdexUrl}>
                  <SmashdexTitleText />
                </Link>
                <Logo />
              </div>
            </ScreenOnly>
            <p>
              A progressive web app for tracking statistics on your Super Smash
              Brothers Melee games. After you make an account, you can add data
              on your game history manually or import it, and then get charts
              and information from that data.
            </p>
            <div className='ProjectName'>
              <span>Web LEDs</span> -{' '}
              <a href='https://github.com/chaseholdren/dween'>
                https://github.com/chaseholdren/dween
              </a>
            </div>
            <p>
              Wired an 8x32 LED Matrix to an Arduino, and created a Node server
              to send LED data to it over the serial port. It got data from{' '}
              <PrintableLink
                text='a website I made'
                link='https://dweenoh.firebaseapp.com/'
                leftPrint='('
                rightPrint=')'
              />{' '}
              where anyone with the url could draw on the grid.
            </p>
            <div className='ProjectName'>
              Writing published on Hackernoon.com
            </div>
            <p>
              <PrintOnly>
                {googleCloudBuildArticleTitle} <br />-{' '}
                <Link url={shortenedGoogleCloudBuildArticleUrl}>
                  {shortenedGoogleCloudBuildArticleUrl}
                </Link>
              </PrintOnly>
              <ScreenOnly>
                <Link url={googleCloudBuildArticleUrl}>
                  {googleCloudBuildArticleTitle}
                </Link>
              </ScreenOnly>
              <br />
              - 13,704 views <br />- 33% read the full article, as estimated by
              Medium
            </p>
          </div> */}
          {/* <hr /> */}
          {/* <div className='Work'>
            <h2>Work</h2>
            <h4>Frameable - Full Stack Developer</h4>
            <span className='employmentDate'>
              {getDateString(frameableStartDate)} -{' '}
              {getDateString(frameableEndDate)}
            </span>
            <p style={{ paddingTop: '.5em' }}>
              Worked on a virtual office web app with video calls and screen
              sharing. The product evolved into Microsoft Teams plugins.
            </p>
            <h4>INSIGHT2PROFIT - Application Developer</h4>
            <span className='employmentDate'>
              {getDateString(insightStartDate)} -{' '}
              {getDateString(insightEndDate)}
            </span>
            <p style={{ paddingTop: '.5em' }}>
              Worked on an internal tool I built with React that is used by our
              analysts to make pricing/quoting models available to clients as an
              API call.
            </p>
            <h4>Hyland Software - Developer 2</h4>
            <span className='employmentDate'>
              {getDateString(hylandStartDate)} - {getDateString(hylandEndDate)}
            </span>
            <p style={{ paddingTop: '.5em' }}>
              Worked on rewriting an API from 4D/Python to C#. Gained experience
              with .NET, Entity Framework, Postgres, Jenkins, and Docker.
            </p>
          </div> */}
          {/* <div className='Learn'>
            <h2>School</h2>
            <h4>Kent State University</h4>
            Graduated 2016 <br />
            Bachelors in Computer Information Systems
          </div> */}
        </div>
        <ScreenOnly>
          <div style={{ paddingBottom: '10vh' }} />
        </ScreenOnly>
      </div>
    );
  }
}

export default App;
